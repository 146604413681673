import { Inject, Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IOrder,
  PUSHER_AUTH_KEY,
  ChannelType,
  IOrderStatusEvent,
  EventType,
  IOrderCourierEvent,
  IOrderDeliveryStatusEvent,
  ICourierLocationEvent,
  ICourierStatusEvent,
  IVenueStatusEvent,
  LocalStorageService,
  PARTNER_KEY
} from '@app/shared';
import Channel from 'pusher-js/types/src/core/channels/channel';
import {PusherChannelResolver} from "@shared/services/pusher-channel-resolver.service";

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private readonly pusher: Pusher;

  private _orderStatusChanged$ = new BehaviorSubject<IOrderStatusEvent | undefined>(undefined);
  private _orderDeliveryStatusChanged$ = new BehaviorSubject<IOrderDeliveryStatusEvent | undefined>(undefined);
  private _courierAssigned$ = new BehaviorSubject<IOrderCourierEvent | undefined>(undefined);
  private _courierCancelled$ = new BehaviorSubject<IOrderCourierEvent | undefined>(undefined);
  private _newOrders$ = new BehaviorSubject<IOrder | undefined>(undefined);

  private _courierLocation$ = new BehaviorSubject<ICourierLocationEvent | undefined>(undefined);
  private _partnerImportsUpdate$ = new BehaviorSubject<any | undefined>(undefined);
  private _courierStatusChanged$ = new BehaviorSubject<ICourierStatusEvent | undefined>(undefined);
  private _venueActivityChanged$ = new BehaviorSubject<IVenueStatusEvent | undefined>(undefined);

  constructor(
    private readonly channelResolver: PusherChannelResolver,
    private readonly localStore: LocalStorageService,
    @Inject(PUSHER_AUTH_KEY) authKey: string,
  ) {
    this.pusher = new Pusher(authKey, {cluster: 'eu', forceTLS: true});
  }

  public subscribe(): void {
    this.subscribeOnPartnerChannel();
    this.subscribeOnOrdersChannel();
    this.subscribeOnDeliveryChannel();
  }

  public unsubscribe(): void {
    this.pusher.allChannels().forEach((channel: Channel) => channel.unsubscribe());
    this.pusher.disconnect();
  }

  public orderStatusChanged(): Observable<IOrderStatusEvent | undefined> {
    return this._orderStatusChanged$.asObservable();
  }

  public orderDeliveryStatusChanged(): Observable<IOrderDeliveryStatusEvent | undefined> {
    return this._orderDeliveryStatusChanged$.asObservable();
  }

  public courierAssigned(): Observable<IOrderCourierEvent | undefined> {
    return this._courierAssigned$.asObservable();
  }

  public courierCancelled(): Observable<IOrderCourierEvent | undefined> {
    return this._courierCancelled$.asObservable();
  }

  public newOrders(): Observable<any | undefined> {
    return this._newOrders$.asObservable();
  }

  public courierLocation(): Observable<ICourierLocationEvent | undefined> {
    return this._courierLocation$.asObservable();
  }

  public courierStatusChanged(): Observable<ICourierStatusEvent | undefined> {
    return this._courierStatusChanged$.asObservable();
  }

  public venueActivityChanged(): Observable<IVenueStatusEvent | undefined> {
    return this._venueActivityChanged$.asObservable();
  }
 
  public importsCatalogChanged(): Observable<any | undefined> {
    return this._partnerImportsUpdate$.asObservable();
  }

  public subscribeOnOrdersChannel(): void {
    let channel = this.channelResolver.getChannelName(ChannelType.ORDER);

    const ordersChannel = this.pusher.subscribe(channel);

    // order status events
    ordersChannel.bind(EventType.ORDER__CONFIRMATION_AWAITING , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__IN_PROGRESS , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__READY_FOR_DELIVERY , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__IN_DELIVERY , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__FITTING , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__DELIVERED , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__CANCELLED , (data: IOrderStatusEvent) => {
      this._orderStatusChanged$.next(data)
    });

    // order courier events
    ordersChannel.bind(EventType.ORDER__COURIER_ASSIGNED , (data: IOrderCourierEvent) => {
      this._courierAssigned$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__COURIER_CANCELLED , (data: IOrderCourierEvent) => {
      this._courierCancelled$.next(data)
    });

    // order delivery status events
    ordersChannel.bind(EventType.ORDER__DELIVERY_DELIVERED , (data: IOrderDeliveryStatusEvent) => {
      this._orderDeliveryStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__DELIVERY_IN_RETURN , (data: IOrderDeliveryStatusEvent) => {
      this._orderDeliveryStatusChanged$.next(data)
    });
    ordersChannel.bind(EventType.ORDER__DELIVERY_RETURNED , (data: IOrderDeliveryStatusEvent) => {
      this._orderDeliveryStatusChanged$.next(data)
    });

    ordersChannel.bind(EventType.ORDER_CREATED, (data: any) => {
      this._newOrders$.next(data as IOrder);
    })
  }

  private subscribeOnDeliveryChannel(): void {
    let channel = this.channelResolver.getChannelName(ChannelType.DELIVERY);
    const deliveryChannel = this.pusher.subscribe(channel);
    deliveryChannel.bind(EventType.DELIVERY_COURIER_LOCATION, (data: ICourierLocationEvent) => {
      this._courierLocation$.next(data);
    });
    deliveryChannel.bind(EventType.DELIVERY_COURIER_STATUS, (data: ICourierStatusEvent) => {
      this._courierStatusChanged$.next(data);
    });
    deliveryChannel.bind(EventType.DELIVERY_VENUE_ACTIVITY, (data: IVenueStatusEvent) => {
      this._venueActivityChanged$.next(data);
    });
  }

  private subscribeOnPartnerChannel(): void {
    const partner: string = this.localStore.get(PARTNER_KEY) as string;
    let channel = `push-channel.partner.${partner}`;
    const partnerChannel = this.pusher.subscribe(channel);
    partnerChannel.bind(EventType.CATALOG_UPDATE, (data: ICourierLocationEvent) => {
      this._partnerImportsUpdate$.next(data);
    });
 
  }
}
